body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.df-inner-stack .ms-Stack-inner,
.df-inner-stack .ms-StackItem {
  margin: 0;
  padding: 0;
}

.df-inner-form-stack .ms-Stack-inner {
  padding: 0;
}

.df-stack-header {
  margin: 0 0 0 0.5rem;
}

.df-stack-header.clickable {
  cursor: pointer;
}

.history-done {
  color: #107c10;
}